import axios from 'axios';
import store from '@/store';
import router from '@/router';

const baseURL = process.env.VUE_APP_API_HOST;
const axs = axios.create({baseURL});
let accessToken;
let refreshToken;

axs.interceptors.request.use(
    config => {
        accessToken = localStorage.getItem('access_token')
        if (accessToken) {
            config.headers.authorization = `JWT ${accessToken}`
        }
        return config
    },
    error => {
        return store.dispatch('updateError', error.response.data.detail)
    }
);

axs.interceptors.response.use(
    config => {
        accessToken = localStorage.getItem('access_token')
        refreshToken = localStorage.getItem('refresh_token')
        if (accessToken) {
            config.headers.authorization = `JWT ${accessToken}`
        }
        return config
    },
    async error => {
        if (error.response.status === 400) {
            return store.dispatch('updateError', error.response.data)
        } else if (error.response.status === 401) {
            if (refreshToken) {
                await axs.post('auth/tokens/refresh', {refreshToken: refreshToken})
                    .then(res => {
                        console.log(res)
                        accessToken = res.data.access_token
                    }).catch(err => {
                        console.log(err)
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                    })
            } else {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
            }
            return location.hash.split('/').pop() !== 'login'
                ? router.push({name: 'Login'})
                : store.dispatch('updateError', "Введены некорректные данные")
        } else {
            return store.dispatch('updateError', error.response.data)
        }
    }
);

export default axs;


